<template>
  <b-dropdown
    right
    variant="white"
    style="padding: 0"
    @show="retrieveNotifications()"
    @hide="markNotificationsRead()"
  >
    <template v-slot:button-content>
      <b-avatar class="mr-2" style="width: 26px;height: 26px;" icon="bell">
      </b-avatar>
      <b-badge
        v-if="unreadNotifications"
        class="notification-badge"
        variant="primary"
        >{{ unreadNotifications }}</b-badge
      >
    </template>

    <div v-if="hasNotifications">
      <b-dropdown-item
        :active="false"
        v-for="(notification, index) in notifications"
        :key="index"
        href="#"
      >
        <b-card
          :class="notification.status === 'new' ? 'notification-new' : ''"
          @click="handleNotificationClick(notification)"
        >
          <b-badge
            class="badge-new"
            v-if="notification.status === 'new'"
            variant="primary"
            >{{ $t("button.new") }}</b-badge
          >
          <b-row style="flex-wrap: nowrap;">
            <div>
              <div style="font-size: 0.6em;">
                <b>
                  {{ notification.createdAt | date("DD-MM-YYYY HH:mm") }}
                </b>
              </div>
              <h7>{{ notification.title }}</h7>
              <p style="font-size: 0.8em;">{{ notification.body }}</p>
            </div>
          </b-row>
        </b-card>
      </b-dropdown-item>
    </div>
    <div v-else>
      <b-dropdown-item>
        {{ $t("emptyNotificationList") }}
      </b-dropdown-item>
    </div>
  </b-dropdown>
</template>

<script>
import { authMixin } from "../../mixins";
export default {
  created() {
    this.retrieveNotifications();
  },
  mixins: [authMixin],
  computed: {
    notifications() {
      const notifications = [
        ...this.$store.getters["notification/notifications"]
      ];
      notifications.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      return notifications;
    },
    unreadNotifications() {
      return this.$store.getters["notification/notificationNum"];
    },
    hasNotifications() {
      return this.notifications.length > 0;
    }
  },
  methods: {
    retrieveNotifications() {
      if (this.$_authUser.type !== "domi") {
        this.$store.dispatch("notification/retrieveNotifications");
      }
    },
    handleNotificationClick(notification) {
      // click_action = notificationType/:condominiumId/:id
      const [
        notificationType,
        condominiumId,
        ...actions
      ] = notification.click_action.split("/");
      switch (notificationType) {
        case "issue":
        case "issueComment":
          var prefix = "";
          if (this.$_authUser.type === "maintainer") {
            prefix = "/maintainer";
          }
          this.$router.push({
            path: `${prefix}/issues/${actions[0]}`
          });
          break;
        default:
          console.log(actions, condominiumId);
          break;
      }
    },
    markNotificationsRead() {
      this.$store.dispatch("notification/markNotificationsRead");
    }
  }
};
</script>

<style lang="scss" scoped>
.b-dropdown {
  /deep/ {
    .dropdown-toggle {
      background-color: transparent;
      border: unset;
      &:focus {
        box-shadow: unset;
      }
    }
    .dropdown-menu {
      width: 350px;
      padding: 0;
      max-height: 400px;
      overflow: auto;
      .dropdown-item {
        padding: 0;
        white-space: pre-wrap;
      }
      .dropdown-item.active,
      .dropdown-item:active {
        color: black;
        background-color: white;
      }
    }
  }
}

.badge-new {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: white;
}

.notification-badge {
  position: relative;
  left: -15px;
  top: -10px;
  border-radius: 50%;
}

.notification-new {
  background: #f59d152b;
}

.card {
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
</style>
